import { SubscriptionContainerAutocompleteField } from "@components/form.types";
import { useMemo, useState } from "react";
import ReactSelect, { GroupBase, StylesConfig } from "react-select";

export type CustomFieldAutocompleteProps = {
  field: SubscriptionContainerAutocompleteField;
  onFieldChange: (name: string, value: string) => void;
  value: string | boolean | number | undefined;
};

export const CustomFieldAutocomplete = ({
  field,
  onFieldChange,
  value,
}: CustomFieldAutocompleteProps) => {
  const [currentValue, setCurrentValue] = useState<string>("");

  const allowedFields = useMemo(() => {
    if (!field.isSearchable) {
      // Show all values to user
      return field.selectOptions;
    }

    if (!currentValue) {
      // Hide all values until user starts typing
      return [];
    }
    // Only show values as user is typing
    return field.selectOptions.filter((option) =>
      option.label.toLowerCase().includes(currentValue.toLowerCase())
    );
  }, [currentValue, field.isSearchable, field.selectOptions]);

  const onCustomFieldChange = (value: string) => {
    onFieldChange(field.id, value);
  };

  const selectedValue = field.selectOptions.find(
    (field) => field.value === value
  );
  const styles: StylesConfig<
    unknown,
    boolean,
    GroupBase<unknown>
  > = field.style ?? {};

  return (
    <ReactSelect
      value={selectedValue}
      options={allowedFields}
      className="text-sm font-medium"
      isClearable={true}
      placeholder={field.placeholder ?? ""}
      onInputChange={(e) => setCurrentValue(e)}
      onChange={(nextValue) => {
        // @ts-expect-error we know value is part of incoming options
        onCustomFieldChange(nextValue?.value ?? "");
        // @ts-expect-error we know value is part of incoming options
        setCurrentValue(nextValue?.value ?? "");
      }}
      styles={styles}
    />
  );
};
