import { Lucide } from "components/common/lucide";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { randomNames, randomPrices } from "./randomTransactionData";

export type LiveTransactionFeedProps = { isActive: boolean };
export const LiveTransactionFeed = ({ isActive }: LiveTransactionFeedProps) => {
  const [todoList, setTodoList] = useState<{ name: string; price: string }[]>([]);

  useEffect(() => {
    addNewTodo();
  }, [isActive]);

  //TODO: Animate these to appear at the top of the list
  const addNewTodo = () => {
    setTodoList([
      {
        name: randomNames[Math.floor(Math.random() * randomNames.length)],
        price: randomPrices[Math.floor(Math.random() * randomPrices.length)],
      },
      {
        name: randomNames[Math.floor(Math.random() * randomNames.length)],
        price: randomPrices[Math.floor(Math.random() * randomPrices.length)],
      },
      {
        name: randomNames[Math.floor(Math.random() * randomNames.length)],
        price: randomPrices[Math.floor(Math.random() * randomPrices.length)],
      },
      {
        name: randomNames[Math.floor(Math.random() * randomNames.length)],
        price: randomPrices[Math.floor(Math.random() * randomPrices.length)],
      },
      {
        name: randomNames[Math.floor(Math.random() * randomNames.length)],
        price: randomPrices[Math.floor(Math.random() * randomPrices.length)],
      },
      ...todoList,
    ]);
  };

  const variants = {
    pre: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <>
      {/* <button onClick={() => addNewTodo()}>add</button> */}
      <motion.ul layout className="direction-reverse max-h-[600px] overflow-y-hidden">
        <AnimatePresence>
          {todoList.map((item, index) => (
            <motion.li
              className="w-full border-slate-300 flex shadow mb-4 rounded-2xl overflow-hidden"
              layout
              variants={variants}
              initial="pre"
              animate="visible"
              key={index}
            >
              <div className="bg-gradient-to-b flex from-[#ebfff1] to-green-50 items-center">
                <Lucide icon="BadgeDollarSign" className="stroke-[1.5px] ml-6 mr-5 my-6 text-green-500" />
              </div>
              <div className="flex bg-white w-full py-4 px-5 justify-between items-center text-green-600">
                <div>
                  <div className="font-bold text-sm">{item.name}</div>
                  <div className="text-xs">Cashed out with {item.name.includes("e") ? "PayPal" : "Venmo"}</div>
                </div>
                <div className="font-bold ml-4">${item.price}</div>
              </div>
            </motion.li>
          ))}
        </AnimatePresence>
      </motion.ul>
    </>
  );
};
