import { Flex, FlexProps, Text, Link as ChakraLink } from "@chakra-ui/react";
import Link from "next/link";

type DefaultLegalCopyProps = FlexProps;

export const DefaultLegalCopy = ({ ...rest }: DefaultLegalCopyProps) => {
  return (
    <Flex flexDir="column" {...rest}>
      <Text>
        I agree to receive recurring automated marketing text messages (e.g.
        cart reminders) at the phone number provided. Consent is not a condition
        to purchase. Msg & data rates may apply. Msg frequency varies. Reply
        HELP for help and STOP to cancel. View our{" "}
        <Link href="/terms-of-service" passHref>
          <ChakraLink color="blue.600">Terms of Service</ChakraLink>
        </Link>{" "}
        and{" "}
        <Link href="/platform-privacy-policy" passHref>
          <ChakraLink color="blue.600">Privacy Policy</ChakraLink>
        </Link>
        .
      </Text>
    </Flex>
  );
};
