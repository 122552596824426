import { AisleLogo } from "@components/AIsleLogo";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "@components/common";
import { cn } from "@utils/cn";
import axios from "axios";

export type ContactCardModalProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  campaignPhoneNumber: string;
};

export const ContactCardModal = ({
  isOpen,
  onOpenChange,
  campaignPhoneNumber,
}: ContactCardModalProps) => {
  const onDownloadContactCard = async () => {
    try {
      const results = await axios.get(
        `/api/v1/contact-card?phoneNumber=${encodeURIComponent(
          campaignPhoneNumber
        )}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(results.data);
      window.location.href = url;
    } catch (error) {}
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[360px] md:max-w-[450px] rounded-3xl sm:rounded-3xl">
        <DialogHeader>
          <div className="flex flex-col justify-center w-full">
            <div className="flex items-center justify-center py-4">
              <AisleLogo />
            </div>
            <div className="pt-5">
              {" "}
              <h1 className="w-full mx-auto text-center md:w-96">
                <span
                  className={cn(
                    "text-pink-400 text-xl md:text-xl font-extrabold md:leading-relaxed",
                    "text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-600",
                    "w-full"
                  )}
                >
                  You are in!
                </span>
              </h1>
            </div>
          </div>
        </DialogHeader>
        <div className="w-full mx-auto text-center md:w-64">
          <span className="text-[16px] font-medium leading-snug text-gray-500">
            Download the contact card to quickly get in touch with us.
          </span>
        </div>
        <DialogFooter className="flex-col justify-center w-full pt-10 space-y-2 sm:flex-col sm:justify-center">
          <Button size={"lg"} onClick={onDownloadContactCard}>
            Download Contact Card
          </Button>
          <Button
            size={"lg"}
            variant={"outline"}
            onClick={() => onOpenChange(false)}
          >
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
