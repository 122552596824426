import * as gtag from "@lib/gtag";
import Image from "next/image";
import React, { useEffect } from "react";

import { Button, Dialog, DialogContent } from "@components/common";
import { encryptString } from "@textnology-inc/shared-types-and-utils/dist/utils";
import { useJoinOffers } from "store/joinOffers.store";
import { LiveTransactionFeed } from "./LiveTransactionFeed";

export type JoinAisleProps = {
  customerPhoneNumber: string;
};

export const JoinAisleModal = ({ customerPhoneNumber }: JoinAisleProps) => {
  const { isUserOptedIn, isSubscriptionFormSubmittedSuccessfully } =
    useJoinOffers();

  const [isOpen, setIsOpen] = React.useState(
    isSubscriptionFormSubmittedSuccessfully
  );

  const bgImagePaths = [
    "/images/logos/logo-aurabora.png",
    "/images/logos/logo-cabinet.png",
    "/images/logos/logo-daring.png",
    "/images/logos/logo-hopwtr.png",
    "/images/logos/logo-vitacoco.png",
    "/images/logos/logo-flings.png",
    "/images/logos/logo-aurabora.png",
    "/images/logos/logo-cabinet.png",
    "/images/logos/logo-supercoffee.png",
    "/images/logos/logo-daring.png",
    "/images/logos/logo-flings.png",
    "/images/logos/logo-hopwtr.png",
    "/images/logos/logo-supercoffee.png",
    "/images/logos/logo-vitacoco.png",
    "/images/logos/logo-wow-bao.png",
  ];

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      //@ts-expect-error grabbing the global gtag function
      window.gtag("event", "close_offers_modal", {
        send_to: `${gtag.AISLE_TRACKING_ID}`,
      });
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(isSubscriptionFormSubmittedSuccessfully);
  }, [isSubscriptionFormSubmittedSuccessfully, isUserOptedIn]);

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="max-w-[90vw] sm:max-w-[50vw] lg:max-w-[864px] grid grid-cols-12 gap-0 p-0 rounded-3xl overflow-hidden">
        <section className="col-span-12 lg:col-span-7 bg-gradient-to-t from-white relative py-6 px-6 lg:px-12 lg:py-24">
          <div className="absolute -z-[1] opacity-20 flex flex-wrap gap-6 -rotate-45 -right-24 -top-12">
            {bgImagePaths.map((imgPath, idx) => {
              return (
                <Image
                  key={idx}
                  width={150}
                  height={100}
                  className="mx-auto grayscale object-contain"
                  alt="Bg logo"
                  src={imgPath}
                />
              );
            })}
          </div>
          <Image
            width={173}
            height={214}
            className="mx-auto"
            alt="Aisle Logo"
            src={"/images/aisle-logo.svg"}
          />
          <h3 className="text-3xl font-black text-center mt-3">
            Access 100+ offers from your favorite brands with just one click!
          </h3>
          <div className="mt-8 flex justify-center">
            <Button
              onClick={() =>
                window.open(
                  `https://discover.gotoaisle.com/offers?phone=${encryptString(
                    customerPhoneNumber,
                    { isFromFrontEnd: true }
                  )}`,
                  "_blank"
                )
              }
              size="lg"
              className="mx-auto px-24"
            >
              Get More Offers
            </Button>
          </div>
          <div className="text-center text-slate-700 text-sm mt-1">
            Join thousands already saving on top brands.
          </div>
        </section>
        <aside className="hidden relative lg:block lg:col-span-5 bg-gradient-to-r h-full border-l border-purple-100 from-purple-50 to-slate-50 pt-16 px-8">
          <LiveTransactionFeed isActive={isOpen} />
          <div className="absolute bottom-0 left-0 gap-4 items-center right-0 flex bg-gradient-to-t  from-90% from-purple-50  px-8 mb-9">
            <div className="relative">
              <Image
                width={118}
                height={118}
                className="mx-auto aspect-square object-cover rounded-full border-purple-500 border-4"
                alt="User Avatar Image"
                src={"/images/logos/testimonial-avatar.png"}
              />
              <div className="absolute text-xs border-4 rounded-full border-purple-500 -bottom-2 -right-3 bg-white px-2 py-1">
                Olivia
              </div>
            </div>
            <blockquote className="text-sm">
              &quot;I saved over $25 in one grocery trip!&quot;
            </blockquote>
          </div>
        </aside>
      </DialogContent>
    </Dialog>
  );
};

export default JoinAisleModal;
