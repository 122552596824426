import {
  Box,
  Button,
  ButtonProps,
  chakra,
  Checkbox,
  CheckboxProps,
  Flex,
  FlexProps,
  FormControlProps,
  Icon,
  IconProps,
  Input,
  InputElementProps,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackProps,
  Text,
  TextProps,
  useDisclosure,
  useToast,
  VStack,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import { Field, FieldAttributes, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";

import { useMediaQuery } from "@utils/useMediaQuery";

import { getErrorMessage } from "@utils/errorHandling/getErrorMessage";
import axios from "axios";
import { FiPhone } from "react-icons/fi";
import * as Yup from "yup";
// import * as gtag from '@lib/gtag'
import { prettifyPhoneNumber } from "@utils/prettifyPhoneNumber";
import { useRouter } from "next/router";
import { BsChatLeftText } from "react-icons/bs";

import { FacebookPixelEventEnum } from "./FacebookPixel/facebookPixel.enums";
import { GoogleTagEventEnum } from "./GoogleTag/googleTag.enums";
import { KlaviyoEventEnum } from "./KlaviyoObject/klaviyo.enums";
import { useCustomerStore } from "store/customer.store";
import { subscriptionStatusCodes } from "@textnology-inc/shared-types-and-utils";
import { phoneNumberRegex } from "@utils/phoneNumberValidation";
import { TikTokPixelEventEnum } from "./TikTokPixel/tikTokPixel.enums";
import { SubscriptionFormConsent } from "./SubscriptionFormConsent";
import { useQueryStore } from "store/query.store";
import { ReferralEnums } from "enums/referral.enum";
import { useJoinOffers } from "store/joinOffers.store";
import { SnapchatPixelEventEnum } from "./SnapchatPixel/snapchatPixel.enums";
import { useVerifyIfOptedIntoAisleMutation } from "services/queries/offers";
import { ExistingConversationModal } from "./ExistingConversationModal";
import { ContactCardModal } from "./ContactCardModal";
import { SubscriptionContainerFields } from "./form.types";
import { CustomFieldsContainer } from "./customFieldRenderer/CustomFieldsContainer";
import { PayoutMethodsEnum } from "@prisma/client";

interface PhoneNumberFormValues {
  phoneNumber: string;
  firstName?: string;
  lastName?: string;
  zipCode?: string;
  email?: string;
  consentBrand?: boolean;
  isConsent?: boolean;
  preferredPayoutMethod?: PayoutMethodsEnum;
}

interface SubscriptionFormProps {
  customerZipCode?: string;
  campaignPhoneNumber: string;
  brandApiKey: string;
  formContainerStyle?: FlexProps;
  formInputContainerStyle?: FormControlProps;
  formInputHeight?: string | string[];
  formButtonStyle?: ButtonProps;
  formButtonCopy?: string;
  formInputStyle?: InputProps;
  formInputPlaceholder?: string;
  withPhoneIcon?: boolean;
  formInputIconStyle?: IconProps;
  formInputIconContainerStyle?: InputElementProps;
  formInputGroupStyle?: InputGroupProps;
  LegalCopyComponent?: React.ReactElement | null;
  isRequireFirstName?: boolean;
  isRequireLastName?: boolean;
  isRequireEmail?: boolean;
  isRequireEmailOptional?: boolean;
  isRequireZipCode?: boolean;
  optInToBrandAndAisle?: boolean;
  optInToBrandAndAisleContainerStyle?: StackProps;
  optInToBrandAndAisleStyle?: TextProps;
  isCampaignWithMultipleLandingPages?: boolean;
  isConsentRequired?: boolean;
  isLegalCopyAboveInputButton?: boolean;
  isLegalCopyAboveSubscribeButton?: boolean;
  subscriptionFormConsentContainerStyle?: FlexProps;
  subscriptionFormCheckboxStyle?: CheckboxProps;
  successToastMessage?: string;
  optInToBrandAndAisleTheme?: "light" | "dark";
  isPepsiOptIn?: boolean;
  isDonateOptIn?: boolean;
  isDrinkingAge?: boolean;
  isLegalEnglish?: boolean;
  legalDrinkingAgeCopy?: string | React.ReactElement;
  formEmailInputPlaceholder?: string;
  customFields?: SubscriptionContainerFields[];
  updatePhoneNumber: (phoneNumber: string) => void;
}

export const SubscriptionForm: React.FC<SubscriptionFormProps> = ({
  customerZipCode,
  campaignPhoneNumber,
  brandApiKey,
  formContainerStyle,
  formInputContainerStyle,
  formInputGroupStyle,
  formInputHeight = "44px",
  formButtonStyle,
  formInputStyle,
  formButtonCopy = "Submit",
  formInputPlaceholder = "Enter phone number...",
  withPhoneIcon = true,
  formInputIconStyle,
  formInputIconContainerStyle,
  LegalCopyComponent = null,
  isRequireFirstName,
  isRequireLastName,
  isRequireEmail,
  isRequireEmailOptional,
  isRequireZipCode,
  optInToBrandAndAisle = false,
  optInToBrandAndAisleContainerStyle,
  optInToBrandAndAisleStyle,
  optInToBrandAndAisleTheme = "light",
  isCampaignWithMultipleLandingPages = false,
  isLegalCopyAboveInputButton = false,
  isConsentRequired = false,
  isLegalCopyAboveSubscribeButton = false,
  subscriptionFormConsentContainerStyle,
  subscriptionFormCheckboxStyle,
  isPepsiOptIn,
  isDonateOptIn,
  legalDrinkingAgeCopy,
  isDrinkingAge = false,
  isLegalEnglish = true,
  formEmailInputPlaceholder,
  customFields = [],
  updatePhoneNumber,
  //successToastMessage = "You should receive a text shortly",
}) => {
  const [customFieldsQuery, setCustomFieldsQuery] = useState<
    Record<string, string | boolean | number>
  >({});

  const {
    isSubscriptionFormSubmittedSuccessfully,
    isPartner,
    setIsUserOptedIn,
    setIsSubscriptionFormSubmittedSuccessfully,
    setCustomerPhoneNumber,
    setBrandApiKey,
    setCampaignPhoneNumber,
  } = useJoinOffers();

  const verifyIfOptedIntoAisleMutation = useVerifyIfOptedIntoAisleMutation();
  const referrerUrl = useCustomerStore.use.referrerUrl();

  // Used to display a dialog if user already has a conversation
  const [isExistingConversationModalOpen, setIsExistingConversationModalOpen] =
    useState<boolean>(false);
  // Used to show the contact card download
  const [isContactCardModalOpen, setIsContactCardModalOpen] =
    useState<boolean>(false);

  const {
    isOpen: isConversationBlocked,
    onOpen: onOpenConversationBlocked,
    onClose: onCloseConversationBlocked,
  } = useDisclosure();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLegalAge, setIsLegalAge] = useState<boolean>(false);
  //const [hasSubmitted, setHasSubmitted] = useState(false)
  const toast = useToast();
  const isMobile: boolean = useMediaQuery(768);
  const router = useRouter();
  const { emailPrefill } = router.query;

  // grab users lat/long from geolocation api
  const [location, setLocation] = useState<{
    latitude?: number;
    longitude?: number;
  }>({});

  const storedQueryParams = useQueryStore.use.stored();

  const shouldShowGeolocation = useMemo(
    () =>
      Math.random() <
      parseInt(process.env.NEXT_PUBLIC_USE_GEOLOCATION_PERCENTAGE || "0") / 100,
    []
  );

  useEffect(() => {
    if (shouldShowGeolocation && "geolocation" in navigator) {
      // Retrieve latitude & longitude coordinates from `navigator.geolocation` Web API
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        const { latitude, longitude } = coords;

        setLocation({ latitude, longitude });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const humanReadableCampaignPhoneNumber = useMemo(() => {
    return prettifyPhoneNumber(campaignPhoneNumber);
  }, [campaignPhoneNumber]);

  const {
    referralCode,
    code,
    utm_campaign,
    utm_source,
    utm_medium,
    utm_content,
    utm_term,
    isTestMode,
  } = router.query;

  const handlePhoneNumberSubmit = async ({
    phoneNumber: customerPhoneNumber,
    firstName,
    lastName,
    email,
    zipCode,
    preferredPayoutMethod,
  }: PhoneNumberFormValues) => {
    setIsSubmitting(true);
    const landingPageSource =
      router.pathname === "/[_slug]" &&
      router.query._slug &&
      typeof router.query._slug === "string"
        ? `/${router.query._slug.replace(ReferralEnums.REDIRECT, "")}`
        : router.pathname;

    const customFieldsSearchParams = new URLSearchParams();

    Object.keys(customFieldsQuery).forEach((key) => {
      if (
        customFieldsQuery[key] ||
        typeof customFieldsQuery[key] === "boolean"
      ) {
        const fieldValue = customFieldsQuery[key]?.toString();
        customFieldsSearchParams.append(key, fieldValue);
      }
    });

    const stringifiedCustomFields = customFieldsSearchParams.toString();

    // Check if a custom field contains the "code" key aka influencer tag
    // If it does, we want to use that value as the influencer tag
    // Otherwise, we use the query param
    const influencerTagFromCustomField = Object.keys(
      customFieldsQuery
    ).includes("code")
      ? customFieldsQuery["code"]
      : code;

    console.log("\n");
    console.log("Payment method:", preferredPayoutMethod);
    console.log("\n");

    try {
      const { data } = await axios.post(
        `${process.env.NEXT_PUBLIC_CONSOLE_DASHBOARD_HOST}/api/webhooks/manual-input`,
        {
          customerPhoneNumber,
          campaignPhoneNumber,
          influencerTag:
            typeof code === "string"
              ? code
              : typeof storedQueryParams.code === "string"
              ? storedQueryParams.code
              : influencerTagFromCustomField ?? undefined,
          utmCampaign:
            typeof utm_campaign === "string"
              ? utm_campaign
              : typeof storedQueryParams.utm_campaign === "string"
              ? storedQueryParams.utm_campaign
              : undefined,
          utmSource:
            typeof utm_source === "string"
              ? utm_source
              : typeof storedQueryParams.utm_source === "string"
              ? storedQueryParams.utm_source
              : undefined,
          utmMedium:
            typeof utm_medium === "string"
              ? utm_medium
              : typeof storedQueryParams.utm_medium === "string"
              ? storedQueryParams.utm_medium
              : undefined,
          utmContent:
            typeof utm_content === "string"
              ? utm_content
              : typeof storedQueryParams.utm_content === "string"
              ? storedQueryParams.utm_content
              : undefined,
          utmTerm:
            typeof utm_term === "string"
              ? utm_term
              : typeof storedQueryParams.utm_term === "string"
              ? storedQueryParams.utm_term
              : undefined,
          referralCode:
            typeof referralCode === "string"
              ? referralCode
              : typeof storedQueryParams.referralCode === "string"
              ? storedQueryParams.referralCode
              : undefined,
          // zipCode comes from this form
          // customer zipCode comes from other forms on the page (such as the store locator)
          zipCode: zipCode ?? customerZipCode,
          firstName,
          lastName,
          email,
          // preferredPayoutMethod -> isDonation prefferred and send donation otherwise send null;
          preferredPayoutMethod,
          referrerUrl: referrerUrl ? referrerUrl : undefined,
          isTestMode: Boolean(isTestMode),
          ...location,
        },
        {
          headers: {
            "X-API-KEY": brandApiKey,
            "X-LANDING-PAGE-SOURCE": landingPageSource,
            "X-LANDING-PAGE-URL": `${window.location.href}${landingPageSource}${
              stringifiedCustomFields ? stringifiedCustomFields : ""
            }`,
            "X-LANDING-PAGE-MULTI": isCampaignWithMultipleLandingPages,
          },
        }
      );
      if (data.code === subscriptionStatusCodes.ConvoAlreadyExists) {
        setIsExistingConversationModalOpen(true);
      } else if (data.code === subscriptionStatusCodes.ConvoStopped) {
        onOpenConversationBlocked();
      } else if (data.code === subscriptionStatusCodes.ConvoReengaged) {
        toast({
          title: "Resubscribed Successfully",
          description: "You should receive a text shortly",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else if (data.code === subscriptionStatusCodes.EmailAlreadyExists) {
        toast({
          title: "Already Subscribed",
          description: "You already have an account associated with this email",
          status: "info",
          duration: 10000,
          isClosable: true,
        });
      } else if (data.code === subscriptionStatusCodes.UserNotEligible) {
        toast({
          title: "Already Redeemed",
          description:
            "We're sorry, it looks like you've already redeemed with this number for another one of our campaigns! If this is a mistake, please email us at support@gotoaisle.com.",
          status: "info",
          duration: 10000,
          isClosable: true,
        });
      } else if (data.code === subscriptionStatusCodes.VoipCustomer) {
        toast({
          title: "Signup Failed...",
          description: "VOIP phone numbers are not permitted.",
          status: "error",
          duration: 40000,
          isClosable: true,
        });
      } else if (data.code === subscriptionStatusCodes.PayoutBlocked) {
        toast({
          title: "Signup Failed...",
          description:
            "We are unable to create your account at this time. Please email us at support@gotoaisle.com.",
          status: "error",
          duration: 20000,
          isClosable: true,
        });
      } else {
        // Throws subscribe event to the hoc component if used
        document.dispatchEvent(
          new CustomEvent(SnapchatPixelEventEnum.SUBSCRIBED, {
            detail: { name: SnapchatPixelEventEnum.SUBSCRIBED },
          })
        );
        document.dispatchEvent(
          new CustomEvent(FacebookPixelEventEnum.SUBSCRIBED, {
            detail: { name: FacebookPixelEventEnum.SUBSCRIBED },
          })
        );
        document.dispatchEvent(
          new CustomEvent(GoogleTagEventEnum.SUBSCRIBED, {
            detail: {
              name: GoogleTagEventEnum.SUBSCRIBED,
            },
          })
        );
        document.dispatchEvent(
          new CustomEvent(KlaviyoEventEnum.SUBSCRIBED, {
            detail: { name: KlaviyoEventEnum.SUBSCRIBED, customerPhoneNumber },
          })
        );
        document.dispatchEvent(
          new CustomEvent(TikTokPixelEventEnum.SUBSCRIBED, {
            detail: { name: TikTokPixelEventEnum.SUBSCRIBED },
          })
        );

        setBrandApiKey(brandApiKey);
        setCampaignPhoneNumber(campaignPhoneNumber);

        toast({
          title: "Subscribed Successfully",
          description: "You should receive a text shortly",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        // Check if brand has opted into aisle brands partners
        if (!isPartner) {
          // Brand is not a partner, so we show the contact card modal
          setIsContactCardModalOpen(true);
          return;
        }

        // Sets the subscription form that is used in the hoc component to
        let hasOptedIntoAisle = false;

        // Check if the user has opted into aisle before, we do this in a try
        // so that if API fails, we still show the modal
        try {
          const results = await verifyIfOptedIntoAisleMutation.mutateAsync({
            phoneNumber: customerPhoneNumber,
          });
          hasOptedIntoAisle = results.isOptedIn;

          // If the user IS opted into Aisle, show JoinAisleModal
          // (different version of the modal which has no form).
          // Users that're already opted into Aisle will have
          // the option of visiting /offers/list
          setIsUserOptedIn(hasOptedIntoAisle);
        } catch (error) {
          console.error(error);
        }

        setIsSubscriptionFormSubmittedSuccessfully(
          !isSubscriptionFormSubmittedSuccessfully
        );
      }
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      console.error(
        `There was an error submitting phone number: ${errorMessage}`
      );

      if (axios.isAxiosError(err) && err.response?.status === 429) {
        toast({
          title: "Subscription error",
          description: `Too many subscriptions have been made from this IP address, please try again in an hour. Failed attempts will increase this timeout.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Subscription error",
          description: `There was an error submitting phone number`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          phoneNumber: "",
          email:
            isRequireEmail && emailPrefill && typeof emailPrefill === "string"
              ? emailPrefill
              : undefined,
        }}
        onSubmit={handlePhoneNumberSubmit}
        // dynamic schema for only properties specified on the individual brand's landing page
        validationSchema={Yup.object().shape({
          phoneNumber: Yup.string()
            .matches(phoneNumberRegex, "This phone number is not valid")
            .required("Phone number is required"),
          firstName: isRequireFirstName
            ? Yup.string().required("First Name is required")
            : Yup.mixed().notRequired(),
          lastName: isRequireLastName
            ? Yup.string().required("Last Name is required")
            : Yup.mixed().notRequired(),
          email: isRequireEmail
            ? isRequireEmailOptional
              ? Yup.string().email("Invalid email").optional()
              : Yup.string()
                  .email("Invalid email")
                  .required("Email is required")
            : Yup.mixed().notRequired(),
          zipCode: isRequireZipCode
            ? Yup.string()
                .matches(/^[0-9]+$/, "Zip code can only be numbers")
                .min(5, "Zip code must be exactly 5 numbers")
                .max(5, "Zip code must be exactly 5 numbers")
                .required("Enter your zip code before submitting")
            : Yup.mixed().notRequired(),
          isConsent: isConsentRequired
            ? Yup.mixed().oneOf([true], "Consent is required")
            : Yup.mixed().notRequired(),
          consentBrand: optInToBrandAndAisle
            ? Yup.mixed().oneOf([true], "Consent is required")
            : Yup.mixed().notRequired(),
          // even if isDonateOptIn is true - the checkbox is not required to be selected
          preferredPayoutMethod: Yup.mixed().notRequired(),
        })}
      >
        {({ values, handleChange }) => (
          <Form style={{ width: "100%" }}>
            <Flex
              justifyContent="center"
              margin="auto"
              marginTop="58px"
              flexDirection={isMobile ? "column" : "row"}
              alignItems={isMobile ? "center" : "flex-start"}
              columnGap="20px"
              rowGap="18px"
              {...formContainerStyle}
            >
              {isLegalCopyAboveInputButton && (
                <>
                  {isConsentRequired ? (
                    <Field name="isConsent">
                      {({
                        form: { handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                      }: FieldAttributes<FieldProps>) => (
                        <SubscriptionFormConsent
                          handleChange={handleChange}
                          LegalCopyComponent={LegalCopyComponent}
                          isChecked={values.isConsent}
                          subscriptionFormConsentContainerStyle={
                            subscriptionFormConsentContainerStyle
                          }
                          subscriptionFormCheckboxStyle={
                            subscriptionFormCheckboxStyle
                          }
                        />
                      )}
                    </Field>
                  ) : (
                    LegalCopyComponent
                  )}
                </>
              )}
              <Field name="phoneNumber">
                {({
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                }: FieldAttributes<FieldProps>) => (
                  <FormControl
                    id="phoneNumber"
                    alignItems="center"
                    flexDirection="column"
                    display="flex"
                    isInvalid={Boolean(
                      errors.phoneNumber && touched.phoneNumber
                    )}
                    width="310px"
                    {...formInputContainerStyle}
                  >
                    <InputGroup
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      {...formInputGroupStyle}
                    >
                      {withPhoneIcon && (
                        <InputLeftElement
                          pointerEvents="none"
                          // eslint-disable-next-line react/no-children-prop
                          children={
                            <Icon
                              as={FiPhone}
                              w="20px"
                              h="20px"
                              color="#667085"
                              {...formInputIconStyle}
                            />
                          }
                          h={formInputHeight}
                          {...formInputIconContainerStyle}
                        />
                      )}

                      <Input
                        _placeholder={{ color: "gray.500" }}
                        color="gray.500"
                        name="phoneNumber"
                        type="tel"
                        placeholder={formInputPlaceholder}
                        value={values?.phoneNumber}
                        focusBorderColor={"primary.500"}
                        onChange={(e) => {
                          // If it's the very first character they enter before submitting
                          // Note: this will also get triggered if they deleted it all and then re-entered it
                          // if (e.target.value.length === 1 && customerPhoneNumber.length === 0 && !hasSubmitted && router.pathname !== "/doughp") {
                          // gtag.event({
                          //   action: 'edit_form',
                          //   category: `Landing Page Header - ${campaignPhoneNumber}`,
                          //   label: `Started inputting phone number: ${e.target.value}`,
                          // })
                          // }

                          handleChange(e);

                          setCustomerPhoneNumber(e.target.value);
                          updatePhoneNumber(e.target.value);
                        }}
                        h={formInputHeight}
                        boxShadow="lg"
                        w="100%"
                        bg="#fff"
                        autoComplete="tel"
                        {...formInputStyle}
                      />
                    </InputGroup>
                    <FormErrorMessage>
                      {typeof errors.phoneNumber === "string"
                        ? errors.phoneNumber
                        : null}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              {isRequireFirstName ? (
                <Field name="firstName">
                  {({
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  }: FieldAttributes<FieldProps>) => (
                    <FormControl
                      id="firstName"
                      alignItems="center"
                      flexDirection="column"
                      display="flex"
                      isInvalid={Boolean(errors.firstName && touched.firstName)}
                      {...formInputContainerStyle}
                      width="25%"
                    >
                      <InputGroup
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        {...formInputGroupStyle}
                      >
                        {withPhoneIcon && (
                          <InputLeftElement
                            pointerEvents="none"
                            // eslint-disable-next-line react/no-children-prop
                            children={
                              <Icon
                                as={FiPhone}
                                w="20px"
                                h="20px"
                                color="#667085"
                                {...formInputIconStyle}
                              />
                            }
                            h={formInputHeight}
                            {...formInputIconContainerStyle}
                          />
                        )}

                        <Input
                          _placeholder={{ color: "gray.500" }}
                          color="gray.500"
                          name="firstName"
                          placeholder={"Enter your name..."}
                          value={values?.firstName}
                          focusBorderColor={"primary.500"}
                          onChange={handleChange}
                          h={formInputHeight}
                          boxShadow="lg"
                          bg="#fff"
                          autoComplete="given-name"
                          {...formInputStyle}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {typeof errors.firstName === "string"
                          ? errors.firstName
                          : null}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              ) : null}
              {isRequireLastName ? (
                <Field name="lastName">
                  {({
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  }: FieldAttributes<FieldProps>) => (
                    <FormControl
                      id="lastName"
                      alignItems="center"
                      flexDirection="column"
                      display="flex"
                      isInvalid={Boolean(errors.lastName && touched.lastName)}
                      {...formInputContainerStyle}
                      width="25%"
                    >
                      <InputGroup
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        {...formInputGroupStyle}
                      >
                        {withPhoneIcon && (
                          <InputLeftElement
                            pointerEvents="none"
                            // eslint-disable-next-line react/no-children-prop
                            children={
                              <Icon
                                as={FiPhone}
                                w="20px"
                                h="20px"
                                color="#667085"
                                {...formInputIconStyle}
                              />
                            }
                            h={formInputHeight}
                            {...formInputIconContainerStyle}
                          />
                        )}

                        <Input
                          _placeholder={{ color: "gray.500" }}
                          color="gray.500"
                          name="lastName"
                          placeholder={"Last Name"}
                          value={values?.lastName}
                          focusBorderColor={"primary.500"}
                          onChange={handleChange}
                          h={formInputHeight}
                          boxShadow="lg"
                          bg="#fff"
                          autoComplete="family-name"
                          {...formInputStyle}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {typeof errors.lastName === "string"
                          ? errors.lastName
                          : null}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              ) : null}
              {isRequireEmail ? (
                <Field name="email">
                  {({
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  }: FieldAttributes<FieldProps>) => (
                    <FormControl
                      id="email"
                      alignItems="center"
                      flexDirection="column"
                      display="flex"
                      isInvalid={Boolean(errors.email && touched.email)}
                      width="310px"
                      {...formInputContainerStyle}
                    >
                      <InputGroup
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        {...formInputGroupStyle}
                      >
                        {withPhoneIcon && (
                          <InputLeftElement
                            pointerEvents="none"
                            // eslint-disable-next-line react/no-children-prop
                            children={
                              <Icon
                                as={FiPhone}
                                w="20px"
                                h="20px"
                                color="#667085"
                                {...formInputIconStyle}
                              />
                            }
                            h={formInputHeight}
                            {...formInputIconContainerStyle}
                          />
                        )}

                        <Input
                          _placeholder={{ color: "gray.500" }}
                          color="gray.500"
                          name="email"
                          placeholder={formEmailInputPlaceholder}
                          value={values?.email}
                          focusBorderColor={"primary.500"}
                          onChange={handleChange}
                          h={formInputHeight}
                          boxShadow="lg"
                          w="100%"
                          bg="#fff"
                          autoComplete="email"
                          {...formInputStyle}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {typeof errors.email === "string" ? errors.email : null}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              ) : null}
              {isRequireZipCode ? (
                <Field name="zipCode">
                  {({
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  }: FieldAttributes<FieldProps>) => (
                    <FormControl
                      id="zipCode"
                      alignItems="center"
                      flexDirection="column"
                      display="flex"
                      isInvalid={Boolean(errors.zipCode && touched.zipCode)}
                      width="310px"
                      {...formInputContainerStyle}
                    >
                      <InputGroup
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        {...formInputGroupStyle}
                      >
                        {withPhoneIcon && (
                          <InputLeftElement
                            pointerEvents="none"
                            // eslint-disable-next-line react/no-children-prop
                            children={
                              <Icon
                                as={FiPhone}
                                w="20px"
                                h="20px"
                                color="#667085"
                                {...formInputIconStyle}
                              />
                            }
                            h={formInputHeight}
                            {...formInputIconContainerStyle}
                          />
                        )}

                        <Input
                          _placeholder={{ color: "gray.500" }}
                          color="gray.500"
                          name="zipCode"
                          placeholder={"Enter your zip code..."}
                          value={values?.zipCode}
                          focusBorderColor={"primary.500"}
                          onChange={handleChange}
                          h={formInputHeight}
                          boxShadow="lg"
                          w="100%"
                          bg="#fff"
                          autoComplete="postal-code"
                          {...formInputStyle}
                        />
                      </InputGroup>
                      <FormErrorMessage>
                        {typeof errors.zipCode === "string"
                          ? errors.zipCode
                          : null}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              ) : null}
              <CustomFieldsContainer
                customFields={customFields}
                onCustomFieldChange={(payload) => setCustomFieldsQuery(payload)}
              />
              {!optInToBrandAndAisle &&
              !isConsentRequired &&
              !isLegalCopyAboveInputButton
                ? LegalCopyComponent
                : null}
              {isConsentRequired &&
              isLegalCopyAboveSubscribeButton &&
              !isLegalCopyAboveInputButton ? (
                <Field name="isConsent">
                  {({
                    form: { handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  }: FieldAttributes<FieldProps>) => (
                    <SubscriptionFormConsent
                      handleChange={handleChange}
                      LegalCopyComponent={LegalCopyComponent}
                      isChecked={values.isConsent}
                      subscriptionFormConsentContainerStyle={
                        subscriptionFormConsentContainerStyle
                      }
                      subscriptionFormCheckboxStyle={
                        subscriptionFormCheckboxStyle
                      }
                    />
                  )}
                </Field>
              ) : null}

              {optInToBrandAndAisle ? (
                <Stack paddingX="5" {...optInToBrandAndAisleContainerStyle}>
                  <Field name="consentBrand">
                    {({
                      form: { errors, handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    }: FieldAttributes<FieldProps>) => {
                      return (
                        <FormControl isRequired>
                          <Flex alignItems="center" rounded="md">
                            <Flex p="4" h="full">
                              <Checkbox
                                name="consentBrand"
                                onChange={handleChange}
                                isChecked={values.consentBrand}
                                borderColor={
                                  optInToBrandAndAisleTheme === "light"
                                    ? "whiteAlpha.300"
                                    : "blackAlpha.300"
                                }
                                bg={
                                  optInToBrandAndAisleTheme === "light"
                                    ? "whiteAlpha.700"
                                    : "white"
                                }
                              />
                            </Flex>
                            <Box
                              px={["0px", "0"]}
                              textAlign={"left"}
                              color="gray.600"
                              fontSize={["x-small", "10px"]}
                              lineHeight="120%"
                            >
                              {isLegalEnglish ? (
                                <Text
                                  color={
                                    optInToBrandAndAisleTheme === "light"
                                      ? "whiteAlpha.700"
                                      : "blackAlpha.700"
                                  }
                                  fontSize="10px"
                                  lineHeight="150%"
                                  fontWeight="500"
                                  letterSpacing="-0.04px"
                                  {...optInToBrandAndAisleStyle}
                                >
                                  By checking this box I provide my express
                                  written consent to receive recurring marketing
                                  text messages from aisle on behalf of the
                                  brand. Consent is not a condition of purchase.
                                  Msg & data rates may apply. Reply HELP for
                                  help and STOP to cancel. I agree to
                                  aisle&apos;s{" "}
                                  <Link
                                    href="/terms-of-service"
                                    target="_blank"
                                    color={
                                      optInToBrandAndAisleTheme === "light"
                                        ? "whiteAlpha.900"
                                        : "blackAlpha.900"
                                    }
                                    textDecoration="underline"
                                  >
                                    Terms of Service
                                  </Link>{" "}
                                  and{" "}
                                  <Link
                                    href="/platform-privacy-policy"
                                    target="_blank"
                                    color={
                                      optInToBrandAndAisleTheme === "light"
                                        ? "whiteAlpha.900"
                                        : "blackAlpha.900"
                                    }
                                    textDecoration="underline"
                                  >
                                    Privacy Policy
                                  </Link>
                                  .
                                </Text>
                              ) : (
                                <Text
                                  color={
                                    optInToBrandAndAisleTheme === "light"
                                      ? "whiteAlpha.700"
                                      : "blackAlpha.700"
                                  }
                                  fontSize="10px"
                                  lineHeight="150%"
                                  fontWeight="500"
                                  letterSpacing="-0.04px"
                                  {...optInToBrandAndAisleStyle}
                                >
                                  Acepto recibir mensajes de texto automáticos
                                  recurrentes de marketing (por ejemplo,
                                  recordatorios de carrito) en el número de
                                  teléfono proporcionado. El consentimiento no
                                  es una condición para comprar. Se pueden
                                  aplicar tarifas de mensajes y datos. La
                                  frecuencia de los mensajes varía. Responde
                                  AYUDA para obtener ayuda y ALTO para cancelar.
                                  Consulta nuestros{" "}
                                  <Link
                                    href="/terms-of-service"
                                    target="_blank"
                                    color={
                                      optInToBrandAndAisleTheme === "light"
                                        ? "whiteAlpha.900"
                                        : "blackAlpha.900"
                                    }
                                    textDecoration="underline"
                                  >
                                    Términos de Servicio
                                  </Link>{" "}
                                  y{" "}
                                  <Link
                                    href="/platform-privacy-policy"
                                    target="_blank"
                                    color={
                                      optInToBrandAndAisleTheme === "light"
                                        ? "whiteAlpha.900"
                                        : "blackAlpha.900"
                                    }
                                    textDecoration="underline"
                                  >
                                    Política de Privacidad
                                  </Link>
                                  .
                                </Text>
                              )}
                            </Box>
                            <FormErrorMessage>
                              {typeof errors.consentBrand === "string"
                                ? errors.consentBrand
                                : null}
                            </FormErrorMessage>
                          </Flex>
                        </FormControl>
                      );
                    }}
                  </Field>
                </Stack>
              ) : null}
              {isDrinkingAge ? (
                <Stack paddingX="5" {...optInToBrandAndAisleContainerStyle}>
                  <Field name="isLegalAge">
                    {({
                      form: { errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    }: FieldAttributes<FieldProps>) => {
                      return (
                        <FormControl isRequired>
                          <Flex alignItems="center" rounded="md">
                            <Flex p="4" h="full">
                              <Checkbox
                                name="isLegalAge"
                                onChange={() => {
                                  setIsLegalAge(!isLegalAge);
                                }}
                                isChecked={isLegalAge}
                                borderColor={
                                  optInToBrandAndAisleTheme === "light"
                                    ? "whiteAlpha.300"
                                    : "blackAlpha.300"
                                }
                                bg={
                                  optInToBrandAndAisleTheme === "light"
                                    ? "whiteAlpha.700"
                                    : "white"
                                }
                              />
                            </Flex>
                            <Box
                              px={["0px", "0"]}
                              textAlign={"left"}
                              color="gray.600"
                              fontSize={["x-small", "10px"]}
                              lineHeight="120%"
                            >
                              <Text
                                color={
                                  optInToBrandAndAisleTheme === "light"
                                    ? "whiteAlpha.700"
                                    : "blackAlpha.700"
                                }
                                fontSize="10px"
                                lineHeight="150%"
                                fontWeight="500"
                                letterSpacing="-0.04px"
                                {...optInToBrandAndAisleStyle}
                              >
                                {legalDrinkingAgeCopy}
                              </Text>
                            </Box>
                            <FormErrorMessage>
                              {typeof errors.isLegalAge === "string"
                                ? errors.isLegalAge
                                : null}
                            </FormErrorMessage>
                          </Flex>
                        </FormControl>
                      );
                    }}
                  </Field>
                </Stack>
              ) : null}

              {isPepsiOptIn ? (
                <Stack paddingX="5" {...optInToBrandAndAisleContainerStyle}>
                  <Field name="pepsiOptIn">
                    {({
                      form: { errors, handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    }: FieldAttributes<FieldProps>) => {
                      return (
                        <FormControl isRequired>
                          <Flex alignItems="center" rounded="md">
                            <Flex p="4" h="full">
                              <Checkbox
                                name="pepsiOptIn"
                                onChange={handleChange}
                                isChecked={values.consentBrand}
                                borderColor={
                                  optInToBrandAndAisleTheme === "light"
                                    ? "whiteAlpha.300"
                                    : "blackAlpha.300"
                                }
                                bg={
                                  optInToBrandAndAisleTheme === "light"
                                    ? "whiteAlpha.700"
                                    : "white"
                                }
                              />
                            </Flex>
                            <Box
                              px={["0px", "0"]}
                              textAlign={"left"}
                              color="gray.600"
                              fontSize={["x-small", "10px"]}
                              lineHeight="120%"
                            >
                              <Text
                                color={
                                  optInToBrandAndAisleTheme === "light"
                                    ? "whiteAlpha.700"
                                    : "blackAlpha.700"
                                }
                                fontSize="10px"
                                lineHeight="150%"
                                fontWeight="500"
                                letterSpacing="-0.04px"
                                {...optInToBrandAndAisleStyle}
                              >
                                {isLegalEnglish
                                  ? "Yes, I’d like to opt-in to receiving additional product information, news and special offers from Rockstar Energy, PepsiCo Tasty Rewards and PepsiCo brands. Please see our Privacy Policy, Terms of Use, and About of Ads for details - you may opt out at any time."
                                  : "Sí, me gustaría optar por recibir información adicional sobre productos, noticias y ofertas especiales de Rockstar Energy, PepsiCo Tasty Rewards y marcas de PepsiCo. Consulta nuestra Política de Privacidad, Términos de Uso y Acerca de los Anuncios para obtener más detalles; puedes optar por salir en cualquier momento."}
                              </Text>
                            </Box>
                            <FormErrorMessage>
                              {typeof errors.consentBrand === "string"
                                ? errors.consentBrand
                                : null}
                            </FormErrorMessage>
                          </Flex>
                        </FormControl>
                      );
                    }}
                  </Field>
                </Stack>
              ) : null}

              {isDonateOptIn ? (
                <Stack paddingX="5" {...optInToBrandAndAisleContainerStyle}>
                  <Field name="preferredPayoutMethod">
                    {({
                      form: { errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    }: FieldAttributes<FieldProps>) => {
                      return (
                        <FormControl>
                          <Flex alignItems="center" rounded="md">
                            <Flex p="4" h="full">
                              <Checkbox
                                name="preferredPayoutMethod"
                                // sets preferredPayoutMethod to PayoutMethodsEnum.donation when the checkbox is checked, and to undefined when unchecked.
                                onChange={(e) => {
                                  const newMethod = e.target.checked
                                    ? PayoutMethodsEnum.donation
                                    : null;
                                  setFieldValue(
                                    "preferredPayoutMethod",
                                    newMethod
                                  );
                                }}
                                // tells the checkbox when it should appear checked
                                isChecked={
                                  values.preferredPayoutMethod ===
                                  PayoutMethodsEnum.donation
                                }
                                borderColor={
                                  optInToBrandAndAisleTheme === "light"
                                    ? "whiteAlpha.300"
                                    : "blackAlpha.300"
                                }
                                bg={
                                  optInToBrandAndAisleTheme === "light"
                                    ? "whiteAlpha.700"
                                    : "white"
                                }
                              />
                            </Flex>
                            <Box
                              px={["0px", "0"]}
                              textAlign={"left"}
                              color="gray.600"
                              fontSize={["x-small", "10px"]}
                              lineHeight="120%"
                            >
                              <Text
                                color={
                                  optInToBrandAndAisleTheme === "light"
                                    ? "whiteAlpha.700"
                                    : "blackAlpha.700"
                                }
                                fontSize="10px"
                                lineHeight="150%"
                                fontWeight="500"
                                letterSpacing="-0.04px"
                                {...optInToBrandAndAisleStyle}
                              >
                                {isLegalEnglish ? (
                                  <>
                                    I wish to donate my rebate to the{" "}
                                    <Link
                                      href="https://texasaggiesunited.com/"
                                      isExternal
                                      textDecoration="underline"
                                      fontWeight="bold"
                                    >
                                      Texas Aggies collective
                                    </Link>
                                    !
                                  </>
                                ) : (
                                  <>
                                    Sí, deseo donar mi reembolso al{" "}
                                    <Link
                                      href="https://texasaggiesunited.com/"
                                      isExternal
                                      textDecoration="underline"
                                      fontWeight="bold"
                                    >
                                      colectivo Texas Aggies
                                    </Link>
                                    !
                                  </>
                                )}
                              </Text>
                            </Box>
                            <FormErrorMessage>
                              {typeof errors.preferredPayoutMethod === "string"
                                ? errors.preferredPayoutMethod
                                : null}
                            </FormErrorMessage>
                          </Flex>
                        </FormControl>
                      );
                    }}
                  </Field>
                </Stack>
              ) : null}
              <Button
                bg="blue.600"
                _hover={{ bg: "blue.500" }}
                color="white"
                isLoading={isSubmitting}
                type="submit"
                w="119px"
                fontSize="16px"
                fontWeight="700"
                h="44px"
                boxShadow="lg"
                {...formButtonStyle}
                isDisabled={isDrinkingAge && !isLegalAge}
              >
                {formButtonCopy}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
      <Modal
        isOpen={isConversationBlocked}
        onClose={onCloseConversationBlocked}
        size={isMobile ? "full" : "xl"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          p={{ base: "0", lg: "10" }}
          rounded={isMobile ? "none" : "xl"}
        >
          <ModalHeader>
            <Flex maxW="96" mx="auto" p="5">
              <Text
                fontFamily='"Inter", sans-serif'
                w="full"
                textAlign="center"
                bgGradient="linear(to-l, #7928CA, #FF0080)"
                bgClip="text"
                fontSize={{ base: "2xl", lg: "2xl" }}
                fontWeight="extrabold"
              >
                It looks like you have unsubscribed from our messages!
              </Text>
            </Flex>
          </ModalHeader>

          <ModalBody>
            <VStack spacing="5" maxW="72" mx="auto">
              <Flex
                w="20"
                h="20"
                bg="gray.50"
                rounded="full"
                justifyContent="center"
                alignItems="center"
                mt={{ base: "10", lg: "0" }}
              >
                <Icon as={BsChatLeftText} color="gray.600" boxSize="8" />
              </Flex>
              <Text
                fontSize={{ base: "md", lg: "lg" }}
                fontWeight="medium"
                color="accent"
                w="full"
                textAlign="center"
              >
                {'To resubscribe, text "START" to '}
                <a
                  href={`sms://${campaignPhoneNumber}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <chakra.span color="blue.600">
                    {humanReadableCampaignPhoneNumber || campaignPhoneNumber}
                  </chakra.span>
                </a>
              </Text>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Stack w="full" justifyContent="center" pt="10" spacing={5} pb="5">
              {isMobile ? (
                <Button
                  colorScheme="gray"
                  variant="solid"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`sms://${campaignPhoneNumber}`);
                  }}
                  w="full"
                  bg="#7928CA"
                  bgGradient="linear(to-r, #D73C8A, #7450D5)"
                  outlineColor="transparent"
                  _hover={{
                    bgGradient: "linear(to-r, #D03063, #724CD7)",
                    outline: "1px solid",
                    outlineColor: "#D73C8A",
                  }}
                  color="white"
                >
                  Send Text
                </Button>
              ) : null}

              <Button
                colorScheme="gray"
                variant="solid"
                w="full"
                onClick={onCloseConversationBlocked}
              >
                Close
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ExistingConversationModal
        isOpen={isExistingConversationModalOpen}
        onOpenChange={setIsExistingConversationModalOpen}
        campaignPhoneNumber={campaignPhoneNumber}
      />
      <ContactCardModal
        isOpen={isContactCardModalOpen}
        onOpenChange={setIsContactCardModalOpen}
        campaignPhoneNumber={campaignPhoneNumber}
      />
    </>
  );
};
