export const randomNames = [
  "Kirsten",
  "Walter",
  "Dexter",
  "Jordan",
  "Kristian",
  "Owen",
  "Audrey",
  "Frederick",
  "Sawyer",
  "James",
  "Brooke",
  "Connie",
  "Adelaide",
  "Lily",
  "Byron",
  "Lydia",
  "Paige",
  "Evelyn",
  "Eddy",
  "Daryl",
  "Aston",
  "Tara",
  "Haris",
  "Heather",
  "Amber",
  "Naomi",
  "Richard",
  "Bruce",
  "Abigail",
  "Nicholas",
  "Lucas",
  "Richard",
  "Chester",
  "Lana",
  "Ryan",
  "Darcy",
  "Eleanor",
  "Miranda",
  "Antony",
  "Briony",
  "Brad",
  "Eddy",
  "Freddie",
  "Garry",
  "Adison",
  "Lenny",
  "Julia",
  "Charlie",
  "Jenna",
  "Kirsten",
  "Reid",
  "Julia",
  "Luke",
  "Dominik",
  "Brianna",
  "Reid",
  "Kirsten",
  "Maya",
  "Carina",
  "Adrian",
  "Arnold",
  "Ryan",
  "Savana",
  "Tiana",
  "Edwin",
  "Oscar",
  "Dominik",
  "Lucy",
  "Miley",
  "Ada",
  "Tyler",
  "Arianna",
  "Briony",
  "Charlie",
  "Emma",
  "Edgar",
  "Richard",
  "Andrew",
  "Kellan",
  "Maximilian",
  "Caroline",
  "Edith",
  "Alan",
  "Julia",
  "Garry",
  "Albert",
  "Kristian",
  "Nicholas",
  "John",
  "Ryan",
  "Walter",
  "James",
  "George",
  "Victoria",
  "Ryan",
  "Jack",
  "Mary",
  "Eric",
  "Alberta",
  "Lyndon",
  "Roman",
  "Frederick",
  "Evelyn",
  "Chester",
  "Camila",
  "Aiden",
  "Elian",
  "Annabella",
  "Haris",
  "Sienna",
  "Haris",
  "Rubie",
  "Natalie",
  "Vincent",
  "Mary",
  "Frederick",
  "Chloe",
  "Rebecca",
  "Nicholas",
  "Reid",
  "Lucia",
  "Eddy",
  "Lucas",
  "Jenna",
  "Nicole",
  "Evelyn",
  "Michelle",
  "James",
  "Martin",
  "Maddie",
  "Joyce",
  "Ned",
  "Kate",
  "Stuart",
  "Arianna",
  "Jasmine",
  "Tara",
  "Chloe",
  "Ted",
  "Daisy",
  "Julian",
  "Wilson",
  "Melanie",
  "Mike",
  "Joyce",
  "Miley",
  "Chelsea",
  "Lyndon",
  "Brooke",
  "Brooke",
  "Chloe",
  "Violet",
  "Sydney",
  "Vivian",
  "Bruce",
  "Ned",
  "Eddy",
  "James",
  "Lana",
  "Brad",
  "Oscar",
  "Naomi",
  "Amanda",
  "Adele",
  "Anna",
  "Byron",
  "Walter",
  "Ned",
  "Wilson",
  "Kelvin",
  "Haris",
  "April",
  "Jared",
  "Madaline",
  "Vincent",
  "Brianna",
  "Sabrina",
  "Derek",
  "Roman",
  "Lily",
  "Kellan",
  "Arianna",
  "Adele",
  "Aida",
  "Kelsey",
  "Audrey",
  "Lydia",
  "Clark",
  "Adrianna",
  "Lydia",
  "Miller",
  "Ryan",
  "Olivia",
  "Wilson",
  "Catherine",
  "Patrick",
  "Tyler",
  "Adam",
  "Violet",
  "Daniel",
];

export const randomPrices = [
  "6.10",
  "3.58",
  "5.90",
  "5.23",
  "4.39",
  "2.37",
  "1.98",
  "2.14",
  "6.09",
  "6.68",
  "6.73",
  "1.86",
  "2.61",
  "2.97",
  "5.52",
  "6.32",
  "6.09",
  "6.93",
  "3.95",
  "2.79",
  "1.03",
  "6.97",
  "4.78",
  "3.87",
  "5.46",
  "6.90",
  "5.32",
  "5.20",
  "5.62",
  "5.32",
  "6.22",
  "4.17",
  "5.81",
  "2.30",
  "6.15",
  "6.62",
  "3.81",
  "4.66",
  "2.26",
  "5.14",
  "4.51",
  "5.65",
  "1.17",
  "2.61",
  "2.86",
  "2.10",
  "6.79",
  "3.27",
  "3.38",
  "3.76",
  "4.84",
  "6.18",
  "5.95",
  "2.10",
  "5.52",
  "1.47",
  "5.17",
  "4.57",
  "3.29",
  "5.00",
  "6.37",
  "1.84",
  "5.47",
  "1.16",
  "6.64",
  "1.12",
  "5.03",
  "1.35",
  "1.53",
  "4.03",
  "6.80",
  "2.97",
  "2.24",
  "5.48",
  "2.71",
  "5.97",
  "4.08",
  "6.54",
  "3.23",
  "2.97",
  "6.34",
  "2.23",
  "6.13",
  "5.84",
  "5.45",
  "6.79",
  "2.78",
  "4.00",
  "3.53",
  "5.37",
  "6.63",
  "5.43",
  "5.53",
  "1.95",
  "6.83",
  "2.78",
  "6.45",
  "1.79",
  "4.07",
  "5.92",
  "6.80",
  "1.83",
  "4.13",
  "1.87",
  "2.52",
  "6.42",
  "3.16",
  "4.79",
  "3.71",
  "5.88",
  "2.06",
  "4.74",
  "3.03",
  "5.25",
  "5.10",
  "5.47",
  "4.83",
  "5.13",
  "4.15",
  "3.99",
  "2.04",
  "6.54",
  "2.22",
  "6.63",
  "2.16",
  "5.32",
  "1.27",
  "2.16",
  "2.81",
  "4.23",
  "6.63",
  "6.26",
  "2.08",
  "2.12",
  "5.89",
  "3.38",
  "5.83",
  "6.24",
  "1.85",
  "3.47",
  "5.06",
  "3.06",
  "3.48",
  "2.59",
  "3.14",
  "1.91",
  "5.47",
  "3.82",
  "5.23",
  "5.58",
  "1.81",
  "4.29",
  "2.59",
  "1.03",
  "1.62",
  "2.46",
  "1.63",
  "3.24",
  "5.53",
  "3.42",
  "3.39",
  "4.06",
  "1.22",
  "5.94",
  "1.97",
  "6.60",
  "3.53",
  "5.85",
  "1.86",
  "5.57",
  "5.42",
  "2.43",
  "2.03",
  "2.55",
  "6.54",
  "3.94",
  "3.67",
  "5.34",
  "4.19",
  "5.32",
  "3.98",
  "3.30",
  "3.31",
  "1.69",
  "1.05",
  "4.63",
  "2.17",
  "1.17",
  "2.03",
  "1.65",
  "6.68",
  "3.75",
  "6.61",
  "1.81",
  "5.71",
  "3.02",
  "1.33",
  "2.49",
  "2.04",
  "2.92",
];
