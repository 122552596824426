import { SubscriptionContainerFields } from "@components/form.types";
import { CustomFieldRenderer } from "./CustomFieldRenderer";
import { useState } from "react";

export type CustomFieldsContainerProps = {
  customFields: SubscriptionContainerFields[];
  onCustomFieldChange: (
    values: Record<string, string | boolean | number>
  ) => void;
};

export const CustomFieldsContainer = ({
  customFields,
  onCustomFieldChange,
}: CustomFieldsContainerProps) => {
  const [fields, setFields] = useState<
    Record<string, string | boolean | number>
  >({});
 
  const onFieldChange = (name: string, value: string) => {
    const newFields = { ...fields, [name]: value };

    setFields(newFields);
    onCustomFieldChange(newFields);
  };

  return (
    <>
      {customFields.map((field) => (
        <CustomFieldRenderer
          field={field}
          key={field.id}
          onFieldChange={onFieldChange}
          value={fields?.[field.id]}
        />
      ))}
    </>
  );
};
