import { create } from "zustand";
import { createSelectors } from "./helpers/createSelectors";

type JoinOffersState = {
  isUserOptedIn: boolean;
  isPartner: boolean;
  brandName: string;
  brandApiKey: string;
  campaignPhoneNumber: string;
  customerPhoneNumber: string;
  isSubscriptionFormSubmittedSuccessfully: boolean;
  isPhoneNumberFormSubmittedSuccessfully: boolean;
};

type JoinOffersActions = {
  setIsPartner: (isPartner: boolean) => void;
  setIsUserOptedIn: (isUserOptedIn: boolean) => void;
  setBrandName: (brandName: string) => void;
  setBrandApiKey: (apiKey: string) => void;
  setCampaignPhoneNumber: (phoneNumber: string) => void;
  setCustomerPhoneNumber: (phoneNumber: string) => void;
  setIsSubscriptionFormSubmittedSuccessfully: (value: boolean) => void;
  setIsPhoneNumberFormSubmittedSuccessfully: (value: boolean) => void;
};

const useJoinOffersStoreBase = create<JoinOffersState & JoinOffersActions>(
  (set) => ({
    brandName: "",
    isPartner: false,
    isUserOptedIn: false,
    brandApiKey: "",
    campaignPhoneNumber: "",
    customerPhoneNumber: "",
    isSubscriptionFormSubmittedSuccessfully: false,
    isPhoneNumberFormSubmittedSuccessfully: false,

    setIsPartner: (isPartner) => set({ isPartner: isPartner }),
    setIsUserOptedIn: (isUserOptedIn) => set({ isUserOptedIn: isUserOptedIn }),
    setBrandName: (brandName) => set({ brandName }),
    setBrandApiKey: (brandApiKey) => set({ brandApiKey }),
    setCampaignPhoneNumber: (campaignPhoneNumber) =>
      set({ campaignPhoneNumber }),
    setCustomerPhoneNumber: (customerPhoneNumber) =>
      set({ customerPhoneNumber }),
    setIsSubscriptionFormSubmittedSuccessfully: (
      isSubscriptionFormSubmittedSuccessfully
    ) => set({ isSubscriptionFormSubmittedSuccessfully }),
    setIsPhoneNumberFormSubmittedSuccessfully: (
      isPhoneNumberFormSubmittedSuccessfully
    ) => set({ isPhoneNumberFormSubmittedSuccessfully }),
  })
);

export const useJoinOffers = createSelectors(useJoinOffersStoreBase);
