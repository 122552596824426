import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { createSelectors } from "./helpers/createSelectors";

export type QueryParams = {
  [key: string]: string | string[] | undefined;
};

type QueryState = {
  stored: QueryParams;
};

type QueryActions = {
  setStored: (queryParams: QueryParams) => void;
};

const useQueryStoreBase = create(
  immer<QueryState & QueryActions>((set) => ({
    stored: {},
    setStored: (queryParams: QueryParams) =>
      set((state) => {
        state.stored = queryParams;
      }),
  }))
);

export const useQueryStore = createSelectors(useQueryStoreBase);
