import {
  Box,
  Checkbox,
  CheckboxProps,
  Flex,
  FlexProps,
  FormControl,
} from "@chakra-ui/react";
import React from "react";

interface SubscriptionFormConsentProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  LegalCopyComponent?: React.ReactElement | null;
  subscriptionFormConsentContainerStyle?: FlexProps;
  subscriptionFormCheckboxStyle?: CheckboxProps;
  isChecked?: boolean;
}

export const SubscriptionFormConsent = ({
  handleChange,
  subscriptionFormConsentContainerStyle,
  subscriptionFormCheckboxStyle,
  LegalCopyComponent,
  isChecked,
}: SubscriptionFormConsentProps) => {
  return (
    <FormControl isRequired>
      <Flex alignItems="center" {...subscriptionFormConsentContainerStyle}>
        <Flex h="full">
          <Checkbox
            p="2"
            name="isConsent"
            onChange={handleChange}
            isChecked={isChecked}
            colorScheme="blue"
            borderColor="blackAlpha.500"
            {...subscriptionFormCheckboxStyle}
          />
        </Flex>
        <Box
          px={["0px", "0"]}
          textAlign={"left"}
          color="gray.600"
          fontSize={["xx-small", "10px"]}
          lineHeight="120%"
        >
          {LegalCopyComponent}
        </Box>
      </Flex>
    </FormControl>
  );
};
