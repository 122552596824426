import { Select } from "@chakra-ui/react";
import { SubscriptionContainerFieldEnums } from "@components/form.enums";
import { SubscriptionContainerFields } from "@components/form.types";
import { CustomFieldAutocomplete } from "./widgets/CustomFieldAutocomplete";

export type CustomFieldRendererProps = {
  field: SubscriptionContainerFields;
  onFieldChange: (name: string, value: string) => void;
  value: string | boolean | number | undefined;
};

export const CustomFieldRenderer = ({
  field,
  onFieldChange,
  value,
}: CustomFieldRendererProps) => {
  const onCustomFieldChange = (value: string) => {
    onFieldChange(field.id, value);
  };

  switch (field.type) {
    case SubscriptionContainerFieldEnums.SELECT:
      return (
        <Select
          onChange={(e) => onCustomFieldChange(e.target.value)}
          placeholder={field.placeholder ?? ""}
          _placeholder={{ color: "gray.500" }}
          color="gray.500"
          {...field.style}
        >
          {field.selectOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      );
    case SubscriptionContainerFieldEnums.AUTOCOMPLETE:
      return (
        <CustomFieldAutocomplete
          field={field}
          onFieldChange={onFieldChange}
          value={value}
        />
      );
    default:
      return null;
  }
};
